/* eslint-disable node/file-extension-in-import */
/* eslint-disable import/extensions */
import React, {
  useRef,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import ReactDOM from 'react-dom/client';
import { RoutesGenerator } from '@poly/client-routing';
import { debounce } from '@poly/utils';

import {
  TextS,
  LogoImageS,
  TextBlockS,
  GlobalStyle,
  AppWrapperS,
  FooterTextS,
  FooterLinkS,
  FooterWrapperS,
  ContentWrapperS,
} from './styles.js';
import {
  ESFMUrl,
  BlockMaxWidth,
  InitialBlockWidth,
  FontSizeMultiplier,
  BlockHeightMultiplier,
  SuppliersInsuranceLink,
} from './constants.js';

function RedirectPage() {
  const ESFMLink = `https://${ESFMUrl}`;
  const calculatedBlockWidth =
    InitialBlockWidth > BlockMaxWidth ? BlockMaxWidth : InitialBlockWidth;

  const blockRef = useRef(null);
  const [blockWidth, setBlockWidth] = useState(calculatedBlockWidth);

  const fontSize = useMemo(() => blockWidth * FontSizeMultiplier, [blockWidth]);
  const blockHeight = useMemo(
    () => blockWidth * BlockHeightMultiplier,
    [blockWidth],
  );

  const redirectToESFMDebounced = useCallback(
    debounce(5000)(() => {
      window.location.replace(ESFMLink);
    }),
    [],
  );

  useEffect(() => {
    redirectToESFMDebounced();

    const handleResize = () => {
      const newBlockWidth = blockRef?.current
        ? blockRef.current.clientWidth
        : calculatedBlockWidth;

      setBlockWidth(newBlockWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AppWrapperS>
      <GlobalStyle />
      <ContentWrapperS href={ESFMLink}>
        <TextBlockS ref={blockRef} style={{ height: blockHeight }}>
          <TextS bold margin="6.5" style={{ fontSize: fontSize * 1.3 }}>
            A. Anthony is now
          </TextS>
          <LogoImageS src="/images/esfm-logo.png" />
          <TextS margin="2.5" style={{ fontSize }}>
            Learn more about what we do at
          </TextS>
          <TextS margin="1" style={{ fontSize }}>
            {ESFMUrl}
          </TextS>
        </TextBlockS>
      </ContentWrapperS>
      <FooterWrapperS>
        <FooterTextS>Please wait while you are redirected...or</FooterTextS>
        <FooterLinkS href={ESFMLink}>Click Here</FooterLinkS>
        <FooterTextS>if you do not want to wait.</FooterTextS>
      </FooterWrapperS>
    </AppWrapperS>
  );
}

function SuppliersInsurancePage() {
  useEffect(() => {
    window.location.replace(SuppliersInsuranceLink);
  }, []);
  return null;
}

const routesConfig = [
  {
    layout: Fragment,
    routes: [
      ['/suppliers', SuppliersInsurancePage],
      ['/*', RedirectPage],
    ],
  },
];

function App() {
  return <RoutesGenerator config={routesConfig} />;
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);

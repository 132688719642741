import * as R from 'ramda';
import styled, { createGlobalStyle } from 'styled-components';

import { BlockMaxWidth, BlockMaxHeight } from './constants.js';

export const GlobalStyle = createGlobalStyle`
    @import url("/fonts.css");

    *,
    ::before,
    ::after {
      box-sizing: inherit;
    }

    html {
      line-height: 1;
      box-sizing: border-box;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-family: 'Montserrat', sans-serif;
      -webkit-tap-highlight-color: transparent;
    }

    html,
    body {
      margin: 0;
      padding: 0;
      height: 100%;
    }
`;

export const AppWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const ContentWrapperS = styled.a`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90%;
  max-height: 70vw;
  text-decoration: none;
  background: url(/images/main-img.png) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  resize: both;
`;

export const LogoImageS = styled.img`
  width: 60%;
  height: auto;
`;

export const TextBlockS = styled.div`
  display: flex;
  width: 75%;
  max-width: ${BlockMaxWidth}px;
  max-height: ${BlockMaxHeight}px;
  z-index: 1;
  background-color: #1b657f;
  opacity: 0.87;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
`;

export const TextS = styled.div`
  display: flex;
  text-align: center;
  letter-spacing: 0.01vw;
  margin-top: ${R.prop('margin')}%;
  font-family: ${({ bold }) => (bold ? 'Montserrat-ExtraBold' : 'Montserrat')},
    sans-serif;
`;

export const FooterWrapperS = styled.div`
  display: inline-block;
  padding: 40px 3.6vw;
  width: 100%;
  max-width: 1200px;
  text-align: center;
`;

export const FooterTextS = styled.div`
  display: inline;
  font-family: sans-serif;
  color: #303030;
  font-size: 15px;

  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

export const FooterLinkS = styled.a`
  display: inline;
  font-family: sans-serif;
  color: #adbc65;
  font-size: 15px;
  margin: 0 5px;
  text-decoration: none;

  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

const { NODE_ENV } = process.env;

const host =
  NODE_ENV === 'development'
    ? 'https://admin-beta.askpoly.co'
    : 'https://admin.askpoly.co';

export const BlockMaxWidth = 935;
export const BlockMaxHeight = 495;
export const FontSizeMultiplier = 0.045;
export const BlockHeightMultiplier = 0.535;
export const ESFMUrl = 'www.esfm-usa.com';
export const InitialBlockWidth = window.innerWidth * 0.75;
export const SuppliersInsuranceLink = `${host}/assets/docs/insurance.pdf`;
